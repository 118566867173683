import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/Users/StephanVolklandANYME/Desktop/gitProjects/customerDocumentation/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "abstractintroduction"
    }}>{`Abstract/Introduction`}</h1>
    <p>{`The purpose of the Number Lookup Service is to be able to identify any MSISDN (internationally formatted number) for its associated real network irrespective of its actual prefix information.`}</p>
    <p>{`This has become necessary due to number portability being implemented in more than half of the countries on the planet.`}</p>
    <p>{`We differentiate between 2 distinct types:`}</p>
    <ul>
      <li parentName="ul">{`MNP lookup: identifying the prefix operator and the real network operator at the time of query and`}</li>
      <li parentName="ul">{`HLR lookup: identifying the prefix operator and the real network operator at the time of query and the subscriber status in case of mobile numbers.`}</li>
    </ul>
    <p>{`In order to use our lookup service you need to send a http request to our domains.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mnp.anymessage.cloud"
        }}>{`https://mnp.anymessage.cloud`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://hlr.anymessage.cloud"
        }}>{`https://hlr.anymessage.cloud`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      